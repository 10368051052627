{
  "width": 1140,
  "height": 780,
  "drawArea": {
    "startX": 38,
    "startY": 57.5,
    "endX": 1102,
    "endY": 722.5
  },
  "layout": {
    "row": 5,
    "column": 8
  },
  "offset": {
    "x": 3,
    "y": 3
  },
  "scale": 11.814159292035399,
  "R": 6,
  "fontSize": 8,
  "direction": "row",
  "assistDirection": "col",
  "withBack": false
}
