import { drawLayout } from './drawLayout'

/**
 * 绘制排版
 * @param ctx 绘制上下文
 * @param drawParam 绘制参数
 */
export async function drawContainer(
  ctx: CanvasRenderingContext2D,
  drawParam: DrawParam,
  containers: SideItem[][][],
  callbacks: {
    // 每次循环开始
    onLoopStart?: (index: number) => void
    // 绘制开始
    onDrawStart?: (side: 'front' | 'back') => void
    // 绘制中
    onDrawing?: (fileName: string, side: 'front' | 'back') => void
    // 绘制结束
    onDrawOver?: (fileName: string, side: 'front' | 'back') => Promise<void>
    // 发生异常时
    onError?: (err: unknown) => void
    // 每回循环结束
    onLoopOver?: (index: number) => void
  }
) {
  /** 绘制方法 */
  const draw = async (
    file: (File | undefined)[][],
    fileName: string,
    side: 'front' | 'back'
  ) => {
    try {
      ctx.reset()
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      // TODO 绘制开始
      callbacks.onDrawStart && callbacks.onDrawStart(side)
      await drawLayout(ctx, drawParam, file)

      // TODO 绘制中
      callbacks.onDrawing && callbacks.onDrawing(fileName, side)

      // TODO 绘制结束
      if (callbacks.onDrawOver) {
        return callbacks.onDrawOver(fileName, side)
      }
    } catch (error) {
      callbacks.onError && callbacks.onError(error)
      console.error(`Draw error: ${fileName}`, error)
    }
  }

  // 当前绘制的下标
  let index = 0
  /** 一个循环绘制的方法 */
  const loop = async () => {
    // TODO 获取绘制的排版容器
    const container = containers[index++]
    if (!container) return void 0 // 已全部绘制完成

    // TODO 获取正面信息
    const front =
      drawParam.direction === 'col'
        ? container.map((row) => row.map((c) => c.front).reverse())
        : container.map((row) => row.map((c) => c.front))

    // TODO 获取反面信息
    const back = drawParam.withBack
      ? drawParam.direction === 'col'
        ? container.map((row) => row.map((c) => c.back))
        : container.map((row) => row.map((c) => c.back).reverse())
      : null

    // TODO 开始绘制
    const fileIndex = index > 9 ? index : '0' + index
    callbacks.onLoopStart && callbacks.onLoopStart(index)

    // TODO 绘制正面
    await draw([...front], `${fileIndex}-正面.png`, 'front')

    // TODO 绘制反面
    if (back) {
      await draw([...back], `${fileIndex}-反面.png`, 'back')
    }

    // TODO 绘制下一面
    callbacks.onLoopOver && callbacks.onLoopOver(index)
    return loop()
  }

  return loop()
}
