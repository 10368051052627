import './withFolderPrint'
import './withButton'
import './withTypes'
import { canvas, hookMake, perviewCtx, previewCanvas } from './define'
import { store } from './store'
import { ZipDownload } from './service/ZipDownload'
import { date } from './helper/date'
import { addUploadInfo } from './helper/addUploadInfo'

// 用户点击排版
hookMake.start.on(() => {
  const filename = `${store.data.name ?? '操作员'}-${date('m.d_h.i')}`
  store.zip = new ZipDownload(filename)
})

// 每次循环结束
hookMake.loopOver.on(() => {
  store.nextCurrent()
})

// TODO 生成预览, 添加预览
hookMake.drawIng.on((fileName) => {
  perviewCtx.clearRect(0, 0, previewCanvas.width, previewCanvas.height)
  perviewCtx.drawImage(canvas, 0, 0, previewCanvas.width, previewCanvas.height)

  addUploadInfo(`绘制 /${fileName}`, 'info')
})

// 绘制完成， 正在保存
hookMake.drawOver.on((fileName) => {
  addUploadInfo(`保存 /${fileName}`, 'warning')
})

// 保存完毕
hookMake.putFile.on((fileName) => {
  addUploadInfo(`已完成 /${fileName}`, 'success')
})

// 压缩包过大时
hookMake.savePart.on(() => {
  addUploadInfo(`正在保存部分文件`, 'warning')
})

// 下载完毕
hookMake.over.on(() => {
  addUploadInfo(`已全部完成`, 'success')
})

// 发现错误时
hookMake.fail.on((err) => {
  addUploadInfo(err.message, 'error')
})
