/**
 * 传入卡片列表，根据layout参数打包成对应的容器
 * @param sides
 * @param layout
 */
export function buildContainer(
  sides: SideItem[],
  layout: LayoutParam,
  layoutSort: Required<DrawParam>['layoutSort']
) {
  const rowContainer: Array<SideItem>[] = []
  const { column } = layout
  const row =
    Math.ceil(
      Math.max(Math.ceil(sides.length / column), layout.row) / layout.row
    ) * layout.row

  for (let r = 0; r < row; r++) {
    const columnContainer: Array<SideItem> = []
    for (let c = 0; c < column; c++) {
      const index = r * column + c
      const side = sides[index]
      if (side) columnContainer.push(side)
      else {
        columnContainer.push({
          folderName: '空白文件夹',
          name: '空白填充',
        })
      }
    }

    rowContainer.push(columnContainer)
  }

  /** 常规排序 */
  const container = new Array(Math.ceil(rowContainer.length / layout.row))
    .fill(0)
    .map((_, index) =>
      rowContainer.slice(index * layout.row, (index + 1) * layout.row)
    )

  // 使用竖排的方式
  if (layoutSort === 'vertical') {
    const verticalContainer = container.map((raw) => {
      const newContainer = raw.map((item) => new Array())

      let columnIndex = 0
      const maxColumn = raw.length
      raw.forEach((rows) => {
        rows.forEach((item) => {
          if (columnIndex >= maxColumn) {
            columnIndex = 0
          }
          newContainer[columnIndex++].push(item)
        })
      })

      return newContainer
    })

    return verticalContainer
  }

  return container
}
