/**
 * 根据 drawArea 绘制栅格线条
 * @param ctx Canvas渲染上下文
 * @param layout 栅格布局（行数和列数）
 * @param drawArea 绘制区域的坐标
 * @param lineWidth 线条宽度
 * @param lineColor 线条颜色
 */
export function drawSubline(
  ctx: CanvasRenderingContext2D,
  drawParam: DrawParam,
  lineWidth: number,
  lineColor = '#000000'
) {
  const { drawArea, layout } = drawParam

  // 保存当前上下文状态
  ctx.save()

  // 设置线条样式
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = lineColor

  // 获取宽度
  const cellWidth = (drawArea.endX - drawArea.startX) / layout.column
  const cellHeight = (drawArea.endY - drawArea.startY) / layout.row

  // 开始绘制路径
  ctx.beginPath()

  // 绘制垂直线条
  for (let i = 0; i <= layout.column; i++) {
    const x = drawArea.startX + i * cellWidth
    ctx.moveTo(x, drawArea.startY)
    ctx.lineTo(x, drawArea.endY)
  }

  // 绘制水平线条
  for (let i = 0; i <= layout.row; i++) {
    const y = drawArea.startY + i * cellHeight
    ctx.moveTo(drawArea.startX, y)
    ctx.lineTo(drawArea.endX, y)
  }

  // 描绘路径
  ctx.stroke()

  ctx.restore()
  /*
  

  // 计算单元格尺寸
  const cellWidth = (drawArea.endX - drawArea.startX) / layout.column
  const cellHeight = (drawArea.endY - drawArea.startY) / layout.row

  // 开始绘制路径
  ctx.beginPath()

  // 绘制垂直线条
  for (let i = 0; i <= layout.column; i++) {
    const x = drawArea.startX + i * cellWidth
    ctx.moveTo(x, drawArea.startY)
    ctx.lineTo(x, drawArea.endY)
  }

  // 绘制水平线条
  for (let i = 0; i <= layout.row; i++) {
    const y = drawArea.startY + i * cellHeight
    ctx.moveTo(drawArea.startX, y)
    ctx.lineTo(drawArea.endX, y)
  }

  // 描绘路径
  ctx.stroke()

  // 恢复上下文状态
  ctx.restore()*/
}
