type ArcArgs = Parameters<CanvasRenderingContext2D['arc']>

/**
 * 传入canvas绘制环境，根据 area 在canvas上下左右4个角绘制黑色的居中实心圆
 * @param ctx 绘制环境
 * @param drawParam 绘制参数
 */
export function appendPositionPoint(
  ctx: CanvasRenderingContext2D,
  drawParam: DrawParam
) {
  const { R, drawArea, offset } = drawParam
  const r = R / 2
  let { startX, startY, endX, endY } = drawArea

  // 设置填充颜色为黑色
  ctx.fillStyle = 'black'

  // 辅助点的位置
  const points: Array<[number, number, number]> = []

  let left = startX - r - offset.x
  let right = endX + r + offset.x
  let top = startY + r + offset.y
  let bottom = endY - r - offset.y

  if (drawParam.assistDirection === 'col') {
    left = startX + r + offset.x
    right = endX - r - offset.x
    top = startY - r - offset.y
    bottom = endY + r + offset.y
  }

  // 左上角
  points.push([left, top, r])
  // 右上角
  points.push([right, top, r])
  // 左下角
  points.push([left, bottom, r])
  // 右下角
  points.push([right, bottom, r])

  // 绘制
  points.forEach(([x, y, radius]) => {
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, Math.PI * 2)
    ctx.fill()
  })
}
