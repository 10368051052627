/**
 * 使用绘制参数的缩放属性重新打包数据
 * @param param
 * @returns
 */
export function useScale(param: DrawParam) {
  const {
    drawArea,
    layout,
    offset,
    R,
    scale,
    width,
    height,
    direction,
    fontSize,
    withBack,
    assistDirection,
  } = param

  const layoutSort = param.layoutSort ?? 'normal'

  const result = {
    width: width * scale,
    height: height * scale,
    drawArea: {
      startX: drawArea.startX * scale,
      startY: drawArea.startY * scale,
      endX: drawArea.endX * scale,
      endY: drawArea.endY * scale,
    },
    drawAreaRoate: {
      startX: drawArea.startX * scale,
      startY: drawArea.startY * scale,
      endX: drawArea.endX * scale,
      endY: drawArea.endY * scale,
    },
    layout,
    layoutSort,
    offset: {
      x: offset.x * scale,
      y: offset.y * scale,
    },
    R: R * scale,
    direction,
    fontSize: fontSize * scale,
    scale,
    assistDirection,
    withBack,
  }

  return result
}
