import { loadImage } from './loadImage'

/**
 * 根据files绘制图片，如果File存在则绘制图形。不存在则留空
 * @param ctx
 * @param drawArea
 * @param container
 */
export function drawLayout(
  ctx: CanvasRenderingContext2D,
  drawParam: DrawParam,
  container: Array<Array<File | undefined>>
) {
  const { drawArea, layout } = drawParam

  let x: number,
    y = drawArea.startY // 当前的坐标系
  const w = Math.abs(drawArea.endX - drawArea.startX) / layout.column
  const h = Math.abs(drawArea.endY - drawArea.startY) / layout.row

  const loopFile = async (files: Array<File | undefined>) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (file) {
        const image = await loadImage(file)
        // 在图片加载完成后绘制到canvas上
        ctx.drawImage(image, x, y, w, h)
      }

      x += w
    }
  }
  const loopFiles = async () => {
    const files = container.shift()
    if (!files) return void 0

    x = drawArea.startX
    await loopFile(files)
    y += h
    await loopFiles()
  }

  return loopFiles()
}
