interface TextConfig {
  fontFamily?: string
  color?: string
}

type Corner = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

/** 获取竖排的定位 */
const getPositionByCol = (
  corner: Corner,
  drawParam: DrawParam,
  measureText: TextMetrics
) => {
  const { offset, drawArea, R, fontSize } = drawParam
  let x = 0,
    y = 0

  switch (corner) {
    case 'topLeft':
      x = drawArea.startX + offset.x * 2 + R + fontSize
      y = drawArea.startY - offset.y * 2

      break
    case 'topRight':
      ;[x, y] = [
        drawArea.endX - measureText.width - offset.x * 2 - R - fontSize,
        drawArea.startY - offset.y * 2,
      ]
      break
    case 'bottomLeft':
      ;[x, y] = [
        drawArea.startX + offset.x * 2 + R + fontSize,
        drawArea.endY + offset.y * 2 + fontSize,
      ]
      break
    case 'bottomRight':
      ;[x, y] = [
        drawArea.endX - measureText.width - offset.x * 2 - R - fontSize,
        drawArea.endY + offset.y * 2 + fontSize,
      ]
      break
    default:
      break
  }

  return [x, y] as const
}

/** 获取横排的定位 */
const getPositionByRow = (
  corner: Corner,
  drawParam: DrawParam,
  measureText: TextMetrics
) => {
  const { offset, drawArea, R, fontSize } = drawParam
  let x = 0,
    y = 0

  switch (corner) {
    case 'topLeft':
      x = -(drawArea.endY - measureText.width / 2 - offset.x - fontSize)
      y = -(measureText.width / 2 - offset.x - drawArea.startX + fontSize)
      break
    case 'topRight':
      x = -(measureText.width / 2 + fontSize)
      y = -(measureText.width / 2 - offset.x - drawArea.startX + fontSize)
      break
    case 'bottomLeft':
      x = drawArea.endY - measureText.width / 2 - offset.x
      y = -(drawArea.endX - offset.y)
      break
    case 'bottomRight':
      x = measureText.width / 2 + offset.x
      y = -(drawArea.endX - offset.y)
      break
    default:
      break
  }

  return [x, y] as const
}

/**
 * 绘制文字
 * @param ctx
 * @param corner
 * @param text
 * @param drawParam
 * @param config
 */
export function drawCornerText(
  ctx: CanvasRenderingContext2D,
  corner: Corner,
  text: string,
  drawParam: DrawParam,
  config?: TextConfig
): void {
  const { fontSize } = drawParam
  const { fontFamily = 'Arial', color = '#000000' } = config ?? {}

  // 设置字体样式
  ctx.font = `${fontSize}px ${fontFamily}`
  ctx.fillStyle = color

  const measureText = ctx.measureText(text)

  ctx.save()

  // 根据不同角落调整位置和方向
  if (drawParam.assistDirection === 'col') {
    const [x, y] = getPositionByCol(corner, drawParam, measureText)
    ctx.translate(x, y)
  } else {
    const angle = Math.PI * (/top/.test(corner) ? 1.5 : 0.5)

    ctx.translate(measureText.width / 2, fontSize / 2)
    ctx.rotate(angle)
    ctx.translate(measureText.width / -2, fontSize / -2)

    const [x, y] = getPositionByRow(corner, drawParam, measureText)
    ctx.translate(x, y)
  }

  ctx.fillText(text, 0, 0)
  ctx.restore()
}
