{
  "width": 1140,
  "height": 780,
  "drawArea": {
    "startX": 12,
    "startY": 17,
    "endX": 768,
    "endY": 1123
  },
  "layout": {
    "row": 7,
    "column": 7
  },
  "offset": {
    "x": 3,
    "y": 3
  },
  "scale": 11.814159292035399,
  "R": 6,
  "fontSize": 8,
  "direction": "col",
  "assistDirection": "col",
  "withBack": false
}
