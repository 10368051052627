{
  "width": 1140,
  "height": 780,
  "drawArea": {
    "startX": 0,
    "endX": 780,
    "startY": 18,
    "endY": 1122
  },
  "layout": {
    "row": 12,
    "column": 13
  },
  "offset": {
    "x": 3,
    "y": 3
  },
  "scale": 11.814159292035399,
  "R": 6,
  "fontSize": 8,
  "direction": "col",
  "assistDirection": "col",
  "withBack": true
}
